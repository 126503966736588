import React from 'react';
import { DateTime } from 'luxon';

import RestoreIcon from '@mui/icons-material/Restore';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import axios from '../../../axiosClient.js';
import ConfirmAction from '../../../components/ConfirmAction.jsx';
import PartsTable from './PartsTable.jsx';
import TaskTable from './TaskTable.jsx';

function PLCData({
  // Props
  plc,
  setTableReload,
}) {
  const plcComplete = React.useMemo(
    () => plc.completedTasks === plc.totalTasks,
    [plc.completedTasks, plc.totalTasks]
  );

  // Toggle `isComplete` depending on current state
  const handleConfirm = React.useCallback(async () => {
    await axios({
      method: 'PATCH',
      url: `/api/plcs/${encodeURIComponent(plc.id)}`,
      data: {
        ...plc,
        isComplete: !plc.isComplete,
        dateOfCompletion: !plc.isComplete ? DateTime.now().toISODate() : undefined,
      },
    });
    setTableReload(true);
  }, [plc, setTableReload]);

  const actionName = React.useMemo(
    () => (plc.isComplete ? 'Reopen' : 'Complete'),
    [plc.isComplete]
  );
  const buttonIcon = React.useMemo(
    () => (plc.isComplete ? <RestoreIcon /> : <VerifiedUserIcon />),
    [plc.isComplete]
  );

  return (
    <Box sx={{ m: { xs: 0, sm: 3 } }}>
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <PartsTable plcId={plc?.id} parts={plc?.parts} setTableReload={setTableReload} />
      </Container>
      <Container maxWidth="xl" sx={{ mt: 5 }}>
        <TaskTable name="Tasks" plcId={plc?.id} tasks={plc.tasks} setTableReload={setTableReload} />
      </Container>
      <Box sx={{ m: 4, textAlign: 'center' }}>
        <ConfirmAction
          actionName={actionName}
          objectName="PLC"
          confirmKeyword={plc.name}
          handleConfirmation={handleConfirm}
          disabled={!plcComplete && !plc.isComplete}
          buttonVariant="contained"
          buttonIcon={buttonIcon}
        />
      </Box>
    </Box>
  );
}

export default PLCData;
