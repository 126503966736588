import React from 'react';
import { DateTime } from 'luxon';

import AssignmentIcon from '@mui/icons-material/Assignment';
import EventIcon from '@mui/icons-material/Event';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ApprovalForm from '../../../components/ApprovalForm.jsx';
import ReadableDocumentNumber from '../../../components/common/ReadableDocumentNumber.jsx';
import TaskForm from '../../../components/TaskForm.jsx';
import DocumentContext from '../../../contexts/DocumentContext.jsx';
import PageContext from '../../../contexts/PageContext.jsx';

function TaskRow({
  // Props
  task,
  plcId,
  setTableReload,
}) {
  const { documents } = React.useContext(DocumentContext);
  const { editMode } = React.useContext(PageContext);

  const today = React.useMemo(() => DateTime.now(), []);
  const dueDateDisplay = React.useMemo(() => {
    if (!task.dueDate) {
      return '-';
    }
    const dueDate = DateTime.fromISO(task.dueDate);
    if (dueDate >= today) {
      return (
        <Stack direction="row" spacing={1}>
          <EventIcon />
          <Typography>{dueDate.toLocaleString()}</Typography>
        </Stack>
      );
    }
    if (task.status.includes('Completed')) {
      const statusDate = DateTime.fromISO(task.statusDate);
      if (statusDate > dueDate) {
        return (
          <Stack direction="row" spacing={1}>
            <EventRepeatIcon color="warning" />
            <Typography>{dueDate.toLocaleString()}</Typography>
          </Stack>
        );
      }
      return (
        <Stack direction="row" spacing={1}>
          <EventAvailableIcon color="success" />
          <Typography>{dueDate.toLocaleString()}</Typography>
        </Stack>
      );
    }

    return (
      <Stack direction="row" spacing={1}>
        <EventBusyIcon color="error" />
        <Typography>{dueDate.toLocaleString()}</Typography>
      </Stack>
    );
  }, [task.dueDate, task.status, task.statusDate, today]);

  return (
    <React.Fragment key={task.name}>
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size={1}>
          <Stack direction="row" sx={{ ml: 1 }}>
            {editMode ? (
              <TaskForm plcId={plcId} task={task} setTableReload={setTableReload} />
            ) : (
              <AssignmentIcon sx={{ m: 1 }} />
            )}
          </Stack>
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 4,
            md: 1.8,
          }}
        >
          <ApprovalForm task={task} plcId={plcId} setTableReload={setTableReload} />
        </Grid>
        <Grid
          sx={{ display: { xs: 'none', sm: 'block' } }}
          size={{
            sm: 4,
            md: 1.5,
            lg: 1.1,
          }}
        >
          {task.section}
        </Grid>
        <Grid
          sx={{ display: { xs: 'none', md: 'block' } }}
          size={{
            sm: 4,
            md: 1.5,
            lg: 1.1,
          }}
        >
          {dueDateDisplay}
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 'grow',
          }}
        >
          {task.name}
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size="grow">
          {!task.trackedDocument && '-'}
          {task.trackedDocument && task.documentNumber && (
            <ReadableDocumentNumber
              documentNumber={task.documentNumber}
              documents={documents}
              link
            />
          )}
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mx: -1 }} />
    </React.Fragment>
  );
}

export default TaskRow;
