import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useSort } from '@tzmedical/react-hooks';

import ColumnHeader from '../../../components/common/ColumnHeader.jsx';
import TaskForm from '../../../components/TaskForm.jsx';
import PageContext from '../../../contexts/PageContext.jsx';
import TaskRow from './TaskRow.jsx';

const sortOptions = {
  defaultSort: {
    field: 'order',
    reverse: false,
  },
  fieldGetters: {
    statusSort: (object) => `${object.status} ${object.statusDate}`,
  },
};

function TaskTable({
  // Props
  name,
  tasks,
  plcId,
  setTableReload,
}) {
  const { editMode } = React.useContext(PageContext);

  const theme = useTheme();

  const [sortedTasks, handleSortSelection, sort] = useSort(tasks || [], sortOptions);

  return (
    <>
      <Grid container spacing={1}>
        <Grid textAlign="center" size={12}>
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
            <Typography variant="h4">{name}</Typography>
            {editMode && <TaskForm plcId={plcId} setTableReload={setTableReload} />}
          </Stack>
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size={1}>
          <ColumnHeader
            id="order"
            display="Default"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 4,
            md: 1.8,
          }}
        >
          <ColumnHeader
            id="statusSort"
            display="Approval"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid
          sx={{ display: { xs: 'none', sm: 'block' } }}
          size={{
            sm: 4,
            md: 1.5,
            lg: 1.1,
          }}
        >
          <ColumnHeader
            id="section"
            display="Department"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid
          sx={{ display: { xs: 'none', md: 'block' } }}
          size={{
            sm: 4,
            md: 1.5,
            lg: 1.1,
          }}
        >
          <ColumnHeader
            id="dueDate"
            display="Due Date"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 'grow',
          }}
        >
          <ColumnHeader
            id="name"
            display="Task"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size="grow">
          <ColumnHeader
            id="documentNumber"
            display="Document"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
      <Divider
        variant="middle"
        color={theme.palette.primary.dark}
        sx={{ borderWidth: '1px', mx: -1 }}
      />
      {sortedTasks.map((task) => (
        <TaskRow key={task.name} task={task} plcId={plcId} setTableReload={setTableReload} />
      ))}
    </>
  );
}

export default TaskTable;
